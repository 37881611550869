<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout class="d-flex">
        <v-layout class="d-flex">
          <v-layout class="mr-2">
            <v-text-field
              v-model="namePrice"
              outlined
              dense
              clearable
              style="width: 190px"
              :maxlength="maximumSmallText"
              :placeholder="$t('Nhập tên bảng giá')"
              @change="namePrice = trimSpace(namePrice)"
              @keydown.enter="searchPrice"
            />
          </v-layout>
          <v-layout v-if="lstPostOffice.length" class="mr-2 col-4 px-0 py-0">
            <v-autocomplete
              v-model="selectPostOffice"
              outlined
              dense
              clearable
              hide-details
              :filter="filterItems"
              :no-data-text="$t('no-data')"
              :placeholder="$t('place_holders.choice-post-office')"
              :items="lstPostOffice"
              item-text="name"
              item-value="id"
            />
          </v-layout>
          <v-layout>
            <v-select
              v-model="partnerSelect"
              outlined
              dense
              item-text="name"
              item-value="code"
              style="width: 180px"
              :items="lstPartner"
              :placeholder="$t('Chọn đơn vị vận chuyển')"
            />
          </v-layout>
        </v-layout>
        <v-layout justify-end class="mb-3">
          <v-btn color="primary" @click="searchPrice">
            {{ $t('search') }}
          </v-btn>
        </v-layout>
      </v-layout>
      <v-layout v-show="currentPoId === constants.postOffice.primary" justify-end class="mb-3">
        <v-btn color="primary" @click="onGotoCreatePoPrices">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t('price_list') }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        :no-data-text="$t('not_found')"
        :headers="headers"
        height="75vh"
        :fixed-header="true"
        :items-per-page="items.length"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
        @click:row="onGotoPoUpdatePrices"
      >
        <template v-slot:[`item.name`]="{ item }">
          <tr class="max-width-6 text-truncate">
            {{ item.name }}
          </tr>
        </template>
        <template v-slot:[`item.partnerId`]="{ item }">
          <td class="max-width-6 text-truncate">
            {{ getTextGroup(item.partnerId) }}
          </td>
        </template>
        <template v-slot:[`item.serviceId`]="{ item }">
          <td class="max-width-6 text-truncate">
            {{ getTextService(item.serviceId) }}
          </td>
        </template>
        <template v-slot:[`item.poName`]="{ item }">
          <td class="max-width-6 text-truncate">
            {{ item.poName }}
          </td>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ formatDate(item.updatedAt) }}
        </template>
      </v-data-table>

      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :icon-src="getIcon"
        :message="message"
      />

      <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import constants from '@/constants'
import filterItems from "@/helpers/filterItems";
import { StorageService } from '@/services/storageService'
import { DateTimeUtils } from '@/helpers/dateTimeUtils.js'
import routePaths from '@/router/routePaths.js'
import { StringUtils } from '@/helpers/stringUtils'
import { PricesService } from '@/services/pricesService'
import { mapActions } from 'vuex'
import { DataUtils } from '@/helpers/dataUtils'
import { PartnerService } from '@/services/partnerService'
import { PostService } from "@/services/postService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    Breadscrumbs
  },
  mixins: [filterItems],
  data() {
    return {
      constants,
      items: [],

      // loading
      showLoading: false,
      showConfirmTokenExpried: false,
      // notifications
      showNoti: false,
      typeIcon: constants.typeAlert.warning,
      message: '',
      maximumSmallText: constants.maximumSmallText,
      namePrice: '',
      selectPostOffice: null,
      lstPartner: [
        {
          name: this.$t('Ninja Van'),
          code: 1
        },
        {
          name: this.$t('Giao hàng tiết kiệm'),
          code: 2
        },
        {
          name: this.$t('Chọn đơn vị vận chuyển'),
          code: null
        }
      ],
      partnerSelect: null,
      serviceSelect: null,
      totalPage: 0,
      page: 1,
      totalRecord: 0,
      onChangeSelect: false,
      btnSearch: false,
      lstService: [],
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
      breadcrumbs: [
        {
          text: 'price_list_po_one',
          disabled: true,
          isActive: true,
        },
      ],
      lstPostOffice: []
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name_prices'),
          value: 'name',
          sortable: true,
          class: 'text-subtitle-2',
        },
        {
          text: this.$t('group-shipping'),
          value: 'partnerId',
          sortable: false,
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('ship_service'),
          value: 'serviceId',
          sortable: false,
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('post_offices'),
          value: 'poName',
          sortable: false,
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('updated_by'),
          value: 'updatedByName',
          sortable: false,
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('updated_date'),
          value: 'updatedAt',
          sortable: false,
          class: 'text-subtitle-2'
        }
      ]
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeIcon)
    },
  },
  created() {
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN") && !this.currentRole.includes("ROLE_ROOT")) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData()
  },
  methods: {
    ...mapActions('layout', ['setBreadcrumbs']),
    async initData() {
      await this.getPrices()
      this.searchPostOffice()
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: "",
      }
    ) {
      this.showNoti = pars.state;
      this.persistent = pars.persistent;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onChangePartnerAll() {
      this.onChangeSelect = true
      if (this.partnerSelect !== null) {
        this.getServiceAll()
      } else {
        this.onChangeSelect = false
        this.serviceSelect = null
      }
    },
    async getServiceAll() {
      try {
        const response = await PartnerService.getServiceAll()
        if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
          this.lstService = response.data.filter((item) => item.deliveryPartnerId === constants.partnerGroup.ninjaVan)
        } else {
          this.lstService = response.data.filter((item) => item.deliveryPartnerId === constants.partnerGroup.ghtk)
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchPostOffice() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await PostService.getPostOffice(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        if (data.totalRecord > 0) {
          this.lstPostOffice = data.data;
        } else {
          this.lstPostOffice = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        keyword: "",
        status: 1,
        limit: 1000,
        currentPage: this.page,
      }
      return pars
    },
    async getPrices() {
      this.showLoading = true
      const pars = {
        name: '',
        poId: this.selectPostOffice ? this.selectPostOffice : null,
      }
      if (this.partnerSelect) {
        pars['partnerId'] = this.partnerSelect
      }
      if (this.namePrice) {
        pars['name'] = this.namePrice
      }
      const { status, data } = await PricesService.getPoPrices(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord
        this.totalPage = DataUtils.customerCalculatePage(data.totalRecord)
        if (data.length) {
          this.items = data
        } else {
          this.items = []
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      }
    },
    searchPrice() {
      this.btnSearch = true
      this.getPrices()
    },
    onGotoCreatePoPrices() {
      this.$router.push(routePaths.PO_PRICE_CREATE_OR_UPDATE_PRICES)
    },
    onGotoPoUpdatePrices(item) {
      this.$router.push({
        path: routePaths.PO_PRICE_CREATE_OR_UPDATE_PRICES,
        query: { detailIdPrice: item.id, partnerId: item.partnerId }
      })
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    getTextGroup(item) {
      if (item === 1) {
        return 'Ninja Van'
      }
      return 'Giao hàng tiết kiệm'
    },
    getTextService(val) {
      if (val === constants.servicesShipping.normal) {
        return this.$t('normal')
      } else if (val === constants.servicesShipping.express) {
        return this.$t('fast')
      } else if (val === constants.servicesShipping.standard) {
        return this.$t('normal')
      } else {
        return this.$t('over')
      }
    },
    formatDate(val) {
      return DateTimeUtils.formatDateTime(val, constants.formatDateTimeHm)
    },
  }
}
</script>

<style src="@/styles/form.scss" lang="scss" />
